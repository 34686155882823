import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const LinkedinAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Linkedin Ads",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/linkedin-ads/",
      }}
    >
      <section className="container-fluid linkedin_section">
        <div className="text-center">
          <h1>Linkedin Ads</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              LinkedIn - the largest network of professionals and decision-makers on one platform. Currently, it has over 500 million users who provide a lot of valuable information about themselves in there.
            </p>
            <p className="text-center">
              No social media platform offers such a business-specific advertising tool as LinkedIn. Are you looking for owners of small furniture companies? Or maybe people in managerial positions in large corporations? Reaching them through LinkedIn campaigns is not a problem. In addition to displaying banner ads to users, you can also send them a message or acquire leads through the portal. Linkedin Ads campaigns work primarily for B2B, but it is also a great tool for recruiting or searching for individual clients.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/banner-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/en/offer/google-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default LinkedinAds;
